<template>
  <v-card outlined flat>
    <v-card-title v-if="isInCreationMode">
      {{ $t('pages.reward_definitions.create_new') }}
    </v-card-title>
    <v-card-text>
      <v-form ref="rewardDefinition" v-model="validRewardDefinition">
        <v-text-field v-bind:label="$t('common.labels.identifier')"
                      name="identifier"
                      type="text"
                      v-model="model.identifier"
                      @keypress.enter.prevent="submit"
                      :rules="[(v) => v !== '' || $t('errors.empty', {the: $t('common.words.The_apostrophe'), name: $t('common.labels.midentifier')})]"/>
        <v-text-field v-bind:label="$t('common.labels.points')"
                      name="points"
                      type="number"
                      v-model="model.amount"
                      @keypress.enter.prevent="submit"
                      :rules="[
                        (v) => v !== '' || $t('errors.empty', {the: $t('common.words.The_plural'), name: $t('common.labels.mpoints')}),
                        (v) => Number(v) > 0 || $t('errors.points')
                      ]"/>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn v-if="isInCreationMode" depressed color="primary" @click="submit"
             :loading="createMixin.state === createMixin.STATES.CREATING"
             :disabled="!validRewardDefinition">
        <span>{{ $t('common.action.create') }}</span>
      </v-btn>
      <v-btn v-else depressed color="primary" @click="submit"
             :loading="updateMixin.state === updateMixin.STATES.CREATING"
             :disabled="!validRewardDefinition">
        <span>{{ $t('common.action.modify') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import RewardDefinitionService from '@/services/gamificator/rewarddefinition.service';
import CreateMixin from '@/mixins/create.mixin';
import UpdateMixin from '@/mixins/update.mixin';

export default {
  name: 'rewardDefinition-creation-rewardDefinition',
  mixins: [CreateMixin, UpdateMixin],
  props: ['value'],
  data: () => ({
    model: {},
    validRewardDefinition: false,
  }),
  watch: {
    value() {
      this.model = { ...this.value };
    },
  },
  methods: {
    submit() {
      this.$refs.rewardDefinition.validate();
      if (!this.validRewardDefinition) return;
      if (this.isInCreationMode) {
        this.create();
      } else {
        this.update();
      }
    },
    createRewardDefinition() {
      return RewardDefinitionService.createRewardDefinition({ type: 'POINT_REWARD', ...this.model });
    },
    updateRewardDefinition() {
      const { id, ...rest } = this.model;
      return RewardDefinitionService.updateRewardDefinition(id, rest);
    },
  },
  computed: {
    isInCreationMode() {
      return this.model.id === null || this.model.id === undefined;
    },
  },
  created() {
    this.createMixin.config = {
      create: 'createRewardDefinition',
    };
    this.updateMixin.config = {
      update: 'updateRewardDefinition',
    };
    this.model = { ...this.value };
  },

};
</script>
