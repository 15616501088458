<template>
  <v-col>
    <reward-definition-save-form @created="addToList"/>
    <v-card class="mt-4" flat tile :loading="isLoading">
      <v-card-title>
        <div>{{ $t('pages.reward_definitions.list_title') }}</div>
      </v-card-title>

      <v-card-text v-if="isError">
        <div>{{ $t('errors.basic') }}</div>
      </v-card-text>

      <v-card-text v-if="rewardDefinitions.length === 0">
        {{ $t('errors.unavailable', {name: $t('common.words.mreward')}) }}
      </v-card-text>

      <v-card-text v-else-if="isIdle || isLoading">
        <v-simple-table>
          <thead>
          <tr>
            <th class="text-center">{{ $t('common.labels.id') }}</th>
            <th class="text-left">{{ $t('common.labels.identifier') }}</th>
            <th class="text-left">{{ $t('common.labels.points_number') }}</th>
            <th class="text-left">{{ $t('common.labels.type') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="rewardDefinition in rewardDefinitions" :key="rewardDefinition.id">
            <td class="text-center">{{ rewardDefinition.id }}</td>
            <td class="text-left">{{ rewardDefinition.identifier }}</td>
            <td class="text-left">{{ rewardDefinition.amount }}</td>
            <td class="text-left">{{ rewardDefinition.type }}</td>
            <td class="text-right">
              <confirm-button width="400px" @click="remove(rewardDefinition.id)">
                <template v-slot:button="{ on, attrs }">
                  <v-btn
                    icon color="error"
                    v-on="on" v-bind="attrs"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>

                <template v-slot:title>
                  {{ $t('pages.reward_definitions.delete_validation_1') }}<br/>
                  {{ $t('pages.reward_definitions.delete_validation_2') }}
                </template>

                <template v-slot:message>
                  {{ $t('pages.reward_definitions.delete_validation_message') }}
                </template>
              </confirm-button>
            </td>
          </tr>
          </tbody>
        </v-simple-table>

        <paginator
          v-if="fetchMixin.pagination"
          :pagination="fetchMixin.pagination"
          @update="updatePagination"
        />
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import RewardDefinitionService from '@/services/gamificator/rewarddefinition.service';
import Paginator from '@/components/util/paginator.vue';

import FetchMixin from '@/mixins/fech.mixin';
import DeleteMixin from '@/mixins/delete.mixin';
import ConfirmButton from '@/components/util/ConfirmButton.vue';
import RewardDefinitionSaveForm from '@/components/rewardDefinition/RewardDefinitionSaveForm.vue';

export default {
  name: 'rewardDefinitionsView',
  mixins: [FetchMixin, DeleteMixin],
  components: {
    ConfirmButton,
    Paginator,
    RewardDefinitionSaveForm,
  },
  data: () => ({
    rewardDefinitions: [],
  }),
  methods: {
    fetchRewardDefinitions() {
      const { page, size } = this.fetchMixin.pagination || { page: 1, size: 20 };
      return RewardDefinitionService.fetchRewardDefinitions({ page, size });
    },
    addToList(rewardDefinition) {
      this.rewardDefinitions = [...this.rewardDefinitions, rewardDefinition];
    },
    removeFromList(id) {
      this.rewardDefinitions = this.rewardDefinitions.filter((rewardDefinition) => rewardDefinition.id !== id);
    },
    deleteRewardDefinition(id) {
      return RewardDefinitionService.deleteRewardDefinition(id);
    },
  },
  created() {
    this.fetchMixin.config = {
      dataField: 'rewardDefinitions',
      fetch: 'fetchRewardDefinitions',
    };
    this.deleteMixin.config = {
      delete: 'deleteRewardDefinition',
      callback: 'removeFromList',
    };
    this.fetch();
  },
};
</script>
